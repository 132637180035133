import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
// import en from '../../../assets/i18n/tuistuisuperuser/en.json';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy
} from '@angular/core';
// import { ROUTES } from './broker-sidebar-items';
import { AuthService } from 'src/app/core/service/auth.service';
import { HttpClient } from '@angular/common/http';
import { RouteInfo } from './broker-sidebar.metadata';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';

declare const Waves: any;

@Component({
  selector: 'app-broker-sidebar',
  templateUrl: './broker-sidebar.component.html',
  styleUrls: ['./broker-sidebar.component.scss']
})
export class BrokerSidebarComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  public sidebarItems: any[];
  ROUTES: RouteInfo[] = [];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  headerHeight = 60;
  routerObj = null;
  leftpanelparentdata : any;
  leftpanelchilddata :any;
  leftpanelsubchilddata: any;
  resultdata : any
  user_name_local=localStorage.getItem('user_name');

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private http:HttpClient
    
  ) { super();
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const currenturl = event.url.split('?')[0];
        this.level1Menu = currenturl.split('/')[1];
        this.level2Menu = currenturl.split('/')[2];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }
  ngOnInit() {
  //  if (this.authService.currentUserValue) {
      let currentUserValue= JSON.parse(localStorage.getItem('currentUser'))
    if (currentUserValue) {

      if(localStorage.getItem('role_name')!=null){

        var role_name = localStorage.getItem('role_name');

      role_name = role_name.replace(/_/g, '').toLowerCase();
      }


      // this.http.get("../../../assets/i18n/"+role_name+"/en.json").subscribe(res=>{

        this.authService.getrolejsondata(role_name).subscribe(res=>{
          
        this.leftpanelparentdata=res;
        
        for(let i=0;i<this.leftpanelparentdata.length;i++){
          this.ROUTES.push({
            path: '',
            title: this.leftpanelparentdata[i].formName,
            moduleName: this.leftpanelparentdata[i].modulePath,
            parent_code:this.leftpanelparentdata[i].parentCode,
            child_code:this.leftpanelparentdata[i].childCode,
            icon: this.leftpanelparentdata[i].iconClass,
            class: 'menu-toggle',
            groupTitle: false,
            sequenceId: this.leftpanelparentdata[i].sequenceId,
            submenu: []
          })
          this.leftpanelchilddata = this.leftpanelparentdata[i].submenu_level1;

          for(let j=0;j<this.leftpanelchilddata.length;j++){
            this.ROUTES.forEach(x=>{
              if(x.parent_code==this.leftpanelchilddata[j].parentCode){
                x.submenu.push({
                  path: this.leftpanelchilddata[j].modulePath+'/'+this.leftpanelchilddata[j].formLink,
                  title: this.leftpanelchilddata[j].formName,
                  moduleName: this.leftpanelchilddata[j].formLink,
                  parent_code:this.leftpanelchilddata[j].parentCode,
                  child_code:this.leftpanelchilddata[j].childCode,
                  icon: this.leftpanelchilddata[j].iconClass,
                  class: 'ml-menu',
                  groupTitle: false,
                  sequenceId: this.leftpanelchilddata[j].sequenceId,
                  submenu: []
                });
              }
            });
            this.leftpanelsubchilddata = this.leftpanelchilddata[j].submenu_level2;
            if(this.leftpanelsubchilddata !== undefined){
              for(let k=0;k<this.leftpanelsubchilddata.length;k++){
                this.ROUTES.forEach(x=>{
                  x.submenu.forEach(y=>{
                    if(y.child_code==this.leftpanelsubchilddata[k].childCode){
                      y.submenu.push({
                        path: this.leftpanelsubchilddata[k].modulePath+'/'+this.leftpanelsubchilddata[k].formLink,
                        title: this.leftpanelsubchilddata[k].formName,
                        moduleName: this.leftpanelsubchilddata[k].formLink,
                        parent_code:this.leftpanelsubchilddata[k].parentCode,
                        child_code:this.leftpanelsubchilddata[k].childCode,
                        icon: this.leftpanelsubchilddata[k].iconClass,
                        class: 'ml-menu',
                        groupTitle: false,
                        sequenceId: this.leftpanelsubchilddata[k].sequenceId,
                        submenu: []
                      });
                    }
                  });
                });
              }
            }
          }
        }
        for(let i=0;i<this.ROUTES.length;i++){
          this.ROUTES[i].submenu.sort((a,b)=>a['sequenceId']>b['sequenceId']?1:a['sequenceId']===b['sequenceId'] ? 0 : -1);
        }
        this.ROUTES.sort((a,b)=>a['sequenceId']>b['sequenceId']?1:a['sequenceId']===b['sequenceId'] ? 0 : -1);

        setTimeout(() => {
          this.sidebarItems=this.ROUTES;
        }, 600);



      // this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);

      });

    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
    
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');

    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');

    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  plans() {
    this.router.navigate(['/membership-plans/buy']);
  }
  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }
}
