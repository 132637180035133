import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';

const refreshTokenKey = 'refresh_token';
const AUTH_API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  code_token = null;
  username: string;
  password: string;
  refresh_token;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getCodeToken(): string {
    return this.code_token;
  }

  get token(): string {
    return localStorage.getItem('token');
  }

  setCodeToken(token, expires_in, username, password): Observable<any> {
    this.code_token = token;
    return this.http.get<any>(
      `${environment.apiUrl}/api/session-data`, {
        params: {
          username: username,
          password: password
        }
    },
    ).pipe(
      map(response => {
        this.setSession(response, expires_in, token);
        this.username = null;
        this.password = null;
        return response;
      }),
      shareReplay(),
    );
  }

  setSession(payload, expires_in, token) {
    const jwtpayload = <JWTPayload>jwtDecode(token);
    const expiresAt = moment.unix(jwtpayload.exp);

    localStorage.setItem('token', token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('user_id', payload.userId.toString());
    localStorage.setItem('currentUser', payload.userId.toString());
    localStorage.setItem('currentUserValue', payload.userId.toString());
    localStorage.setItem('tenant_id', payload.companyId);
    localStorage.setItem('role_id', payload.roleId);
    localStorage.setItem('role_name', payload.roleName);
    localStorage.setItem('company_type_ref_id', payload.companyRefIf);
    localStorage.setItem('user_name', payload.username);
    localStorage.setItem('company_type', payload.companyType);
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getrolejsondata(role_name) {
    return this.http.get(AUTH_API+'/api/question/read/'+role_name+'.json');
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  taxRateInformation(contryId) {
    return this.http.get("/api/membership-details/tax-information/" + contryId );
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  forgotPassword(email) {
    return this.http.get(`${environment.apiUrl}/api/change-password/` + email);
  }

  changePassword(form) {
    return this.http.put(`${environment.apiUrl}/api/change-password`, form);
  }

  isSubscribed(id) {
    return this.http.get(`${environment.apiUrl}/api/membership-details/subscribe/` + id).pipe(
      map(response => {
        return response;
      }))
  }

  getBrokerPlanDetails(id) {
    return this.http.get(`${environment.apiUrl}/api/membership-details/broker-plan/` + id).pipe(
      map(response => {
        return response;
      }))
  }

  getBrokerDetails(tenant_id) {
    return this.http.get(`${environment.apiUrl}/api/membership-details/broker-information/` + tenant_id).pipe(
      map(response => {
        return response;
      }))
  }

  code(username: string, password: string) {
    this.username = username;
    this.password = password;
    return this.http.post(
      `${environment.apiUrl}/auth/code/`,
      { username, password }
    )
  }

  ResetPassword(username: string) {
    this.username = username;
    return this.http.post(
      `${environment.apiUrl}/auth/reset-password/`,
      { username }
    )
  }

  UpdatePassword(user) {
    return this.http.post(`${environment.apiUrl}/auth/update-password/`, user)
  }

  login(username, password) {
    const headers = new HttpHeaders()
      .append('Authorization', 'Basic ' + btoa('tuistui-client:secret'));
    const param = new HttpParams()
      .set("username", username)
      .set("password", password)
      .set("grant_type", "password");

    return this.http.post(`${environment.apiUrl}` + '/oauth/token', param, { headers })
      .pipe(map(res => {
        localStorage.setItem('token', res['access_token']);
        localStorage.setItem('expires_at', JSON.stringify(res['expires_in']));
        return res;
      }));
  }

  logout() {
    const token = localStorage.getItem('token');
    this.http.get(`${environment.apiUrl}/api/tokens/revoke/`+token);
    
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_id');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserValue');
    localStorage.removeItem('tenant_id');
    localStorage.removeItem('role_id');
    localStorage.removeItem('role_name');
    localStorage.removeItem('company_type_ref_id');
    localStorage.removeItem('user_name');
    localStorage.removeItem('company_type');
    localStorage.clear();
    return of({ success: false });
  }

  refreshToken() {
    if (moment().isBetween(this.getExpiration().subtract(1, 'days'), this.getExpiration())) {
      const token = localStorage.getItem("refresh_token");
      const headers = new HttpHeaders()
        .append('Authorization', 'Basic ' + btoa('tuistui-client:secret'));
      const param = new HttpParams()
        .set(refreshTokenKey, token)
        .set("grant_type", refreshTokenKey);
      return this.http.post(`${environment.apiUrl}` + '/oauth/token', param, { headers })
        .pipe(map(res => {
          this.refresh_token=res['refresh_token'];
          localStorage.setItem('token', res['access_token']);
          localStorage.setItem("refresh_token", res['refresh_token']);
          return res;
        },
          shareReplay()));
    }
  }

  getOtp(user){
    return this.http.post(`${environment.apiUrl}/api/get-otp`,user,{responseType:'text'});
  }

  verifyOtp(username,otp){
    return this.http.get(`${environment.apiUrl}/api/verify-otp/`+username+'/'+otp);
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const webservicetoken=localStorage.getItem('webservicetoken');

    if (token) {
      const cloned = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      this.authService.refreshToken();
      return true;
    } else {
      this.authService.logout();
      this.router.navigate(['']);

      return false;
    }
  }
}

export interface JWTPayload {
  exp: number;
}
