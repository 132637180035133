
    <!-- Left Sidebar -->
    <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)" style="background-color: transparent;">
        <div style="background-color: transparent;margin-bottom: 0rem;margin-top: 0rem;">
            <p style="padding: 1em;margin-bottom: 0%;font-size: 1em;word-wrap: break-word;">
              <i class="fas fa-circle" style="color: #198754;"></i>
              {{user_name_local}}
            </p>
            <!-- <a href="javascript:void(0);" (click)="logout()"
            style="padding: 1em;margin-bottom: 0%;
            font-size: 1.2em;font-weight: bold;
            padding: 0.5em;border-radius: 0.5em;">
              Log Out
            </a> -->
        
        </div>
      <!-- Menu -->
      <div class="menu" style="background-color: transparent;">
        
        
        <ul class="list" style="cursor: pointer;margin-bottom: 0%;"
          [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
          [perfectScrollbar]>
          
          <!-- Top Most level menu -->
          <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
            *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
            <!-- <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}1</div> -->

            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
              [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
              <i [ngClass]="[sidebarItem.icon]"></i>
              <span class="hide-menu">{{sidebarItem.title | translate}}
              </span>
            </a>
            <!-- First level menu -->
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
                *ngFor="let sidebarSubItem of sidebarItem.submenu"
                [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
                <a style="margin-left: 1.5rem !important;" [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                  (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                  <i class="fas fa-angle-right" style="font-size: 1rem;"></i> {{sidebarSubItem.title | translate}}
                </a>
                <!-- Second level menu -->
                <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                  <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                    [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                    [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                      (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                      [ngClass]="[sidebarSubsubItem.class]">
                      {{sidebarSubsubItem.title | translate}}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          
          <li>
            <div *ngIf="sidebarItems">
              <a href="javascript:void(0);" (click)="logout()" style="color: #fff;height: 2.5rem;font-size: 1rem !important;" class="menu-top">
                 Log Out
                
              </a>
              </div>
            

          </li>
        </ul>
      </div>
      <!-- #Menu -->
    </aside>
    <!-- #END# Left Sidebar -->
  
  