import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { BrokerLayoutComponent } from './layout/app-layout/broker-layout/broker-layout.component';

const routes: Routes = [
  {
    path: '',
    component: BrokerLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'master',
        loadChildren: () =>
          import('./master/master.module').then((m) => m.MasterModule)
      },
      {
        path: 'broker-setup',
        loadChildren: () =>
          import('./broker-setup/broker-setup.module').then((m) => m.BrokerSetupModule)
      },
      {
        path: 'company-setup',
        loadChildren: () =>
          import('./company-setup/company-setup.module').then((m) => m.CompanySetupModule)
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('./membership/membership.module').then((m) => m.MembershipModule)
      },
      {
        path: 'client',
        loadChildren: () =>
          import('./client/client.module').then((m) => m.ClientModule)
      },
      {
        path: 'membership-plans',
        loadChildren: () =>
        import('./membership-plans/membership-plans.module').then((m) => m.MembershipPlansModule)
      },
      {
        path: 'product-setup',
        loadChildren: () =>
          import('./product-setup/product-setup.module').then((m) => m.ProductsetupModule)
      },
      {
        path: 'insurance',
        loadChildren: () =>
          import('./insurance/insurance.module').then((m) => m.InsuranceModule)
      },
      
      {    
        path: 'insurance-question-answer',
        loadChildren: () =>
          import('./insurance-question-answer/insurance-question-answer.module').then((m) => m.InsuranceQuestionAnswerModule)
      },
      {    
        path: 'rfq-quote',
        loadChildren: () =>
          import('./rfq-quote/rfq-quote.module').then((m) => m.RfqQuoteModule)
      },
      {
        path: 'compare-quote',
        loadChildren: () =>
          import('./compare-quote/compare-quote.module').then((m) => m.CompareQuoteModule)
      }
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  // {
  //   path: 'broker',
  //   component: BrokerLayoutComponent,
  //   canActivate: [AuthGuard],
  //   children:[
  //     {
  //       path: 'client',
  //       loadChildren: () =>
  //         import('./client/client.module').then((m) => m.ClientModule)
  //     },
  //     {
  //       path: 'membership-plans',
  //       loadChildren: () =>
  //       import('./membership-plans/membership-plans.module').then((m) => m.MembershipPlansModule)
  //     },
  //     {
  //       path: 'broker-setup',
  //       loadChildren: () =>
  //         import('./broker-setup/broker-setup.module').then((m) => m.BrokerSetupModule)
  //     },
  //     {
  //       path: 'insurance',
  //       loadChildren: () =>
  //         import('./insurance/insurance.module').then((m) => m.InsuranceModule)
  //     }
  //   ]
  // },

  
  // {
  //   path: 'membership-plans',
  //   loadChildren: () =>
  //     import('./membership-plans/membership-plans.module').then((m) => m.MembershipPlansModule)
  // },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
