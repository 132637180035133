import { RouteInfo } from './sidebar.metadata';

var role_name = localStorage.getItem('role_name');


export const ROUTES: RouteInfo[] = [
  // {
  //   path: '',
  //   title: 'MENUITEMS.HOME.TEXT',
  //   moduleName: 'MENUITEMS.HOME.MODULE',
  //   icon: 'monitor',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/dashboard/main',
  //       title: 'MENUITEMS.HOME.LIST.TEXT',
  //       moduleName: 'MENUITEMS.HOME.MODULE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.COMMON-SETUP.TEXT',
  //   moduleName: 'MENUITEMS.COMMON-SETUP.MODULE',
  //   icon: 'monitor',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/master/all-master',
  //       title: 'MENUITEMS.COMMON-SETUP.LIST.ALLMASTER',
  //       moduleName: 'MENUITEMS.COMMON-SETUP.MODULE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/master/uom-master',
  //       title: 'MENUITEMS.COMMON-SETUP.LIST.UOMMASTER',
  //       moduleName: 'MENUITEMS.COMMON-SETUP.MODULE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/master/country-currency-master',
  //       title: 'MENUITEMS.COMMON-SETUP.LIST.COUNTRYMASTER',
  //       moduleName: 'MENUITEMS.COMMON-SETUP.MODULE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
    
    
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.COMPANY-SETUP.TEXT',
  //   moduleName: 'MENUITEMS.COMPANY-SETUP.MODULE',
  //   icon: 'monitor',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/company-setup/company',
  //       title: 'MENUITEMS.COMPANY-SETUP.LIST.COMPANY',
  //       moduleName: 'MENUITEMS.COMPANY-SETUP.MODULE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
];
