<nav #navbar class="navbar">
    <div class="container-fluid">
      <div class="navbar-header" style="border-right: none !important;">
        <a href="#" onClick="return false;" class="navbar-toggle collapsed"
          (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"  style="z-index: 999 !important;"></a>
        <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"  style="z-index: 999 !important;"></a>
        <a class="navbar-brand" routerLink="dashboard/main"  style="z-index: 999 !important;">
          <img src="assets/images/tuistui_logo2.png" alt="" />
          
        </a>
      </div>
      <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
        <!-- <ul class="float-start collapse-menu-icon">
          <li>
            <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
              <i class="nav-hdr-btn ti-align-left"></i>
            </button>
          </li>
        </ul> -->
        <ul class="nav navbar-nav navbar-right">
          <!-- Full Screen Button -->
          <!-- <li class="fullscreen">
            <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
              <i class="nav-hdr-btn ti-fullscreen"></i>
            </button>
          </li> -->
       
          <!-- <li class="nav-item user_profile" ngbDropdown>
            <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
              <img src="../../../assets/images/brokeruser.png" class="rounded-circle" width="60" height="60" alt="User"> -->
              <!-- {{user_name_local}} -->
            <!-- </a>
            <div ngbDropdownMenu class="notification-dropdown pullDown">
              <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
                <ul class="menu">
                  <li class="body">
                    <ul class="user_dw_menu">
                      <li>
                        <a href="#" onClick="return false;">
                          <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick="return false;">
                          <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick="return false;">
                          <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                        </a>
                      </li>
                      <ng-container *ngIf="isBroker">
                        <li>
                          <a href="javascript:void(0);" (click)="plans()">
                            <mat-icon aria-hidden="false" class="msr-2">monetization_on</mat-icon>{{headerplanname}}
                          </a>
                        </li>
                      </ng-container>
                      
                      <li>
                        <a href="javascript:void(0);" (click)="logout()">
                          <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li> -->
          <!-- #END# Tasks -->
        </ul>
      </div>
    </div>
  </nav>
  