import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate
} from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate() {
    if (this.authService.isLoggedIn()) {
      this.authService.refreshToken();
      return true;
    } else {
      this.router.navigate(['/authentication/signin']);
      return false;
    }
  }
}
